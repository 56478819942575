@import "../../vars.scss";
.wrapper {
  height: 20px;
  width: 20px;
  position: relative;
  color: $textSecondary;
  .a,
  .b,
  .c {
    position: absolute;
    fill: currentColor;
    transform: rotate(0);
    transition: 0.2s top 0.4s, 0.1s left 0.2s, 0.2s transform, 0.2s opacity 0.2s;
  }
  .a {
    left: 0;
    top: 2px;
  }
  .b {
    top: 9px;
    left: 0;
  }
  .c {
    left: 0px;
    top: 16px;
  }
  &.isOpen {
    .a,
    .b,
    .c {
      transition: 0.2s opacity, 0.2s top, 0.2s left 0.2s, 0.2s transform 0.4s;
    }
    .a,
    .c {
      right: auto;
      left: 3px;
      top: 9px;
    }
    .b {
      opacity: 0;
    }
    .a {
      transform: rotate(-45deg);
    }
    .c {
      transform: rotate(45deg);
    }
  }
}
