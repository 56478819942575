@import '_vars.scss';
.box {
  width: 300px;
  background: white;
  border: 1px solid lighten($bgDark, 70%);
  padding: 20px 30px;
  margin: 0 10px 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 400;
  font-weight: .875rem;
  &.success {
    border-left: 5px solid $primary;
  }
  &.error {
    border-left: 5px solid $red;
  }
  .p {
    color: $textMain;
  }
}