@import "../../../../vars";

.main {
  width: 80%;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 2px 10px #aaaa;
  padding: 35px;
  margin-top: 50px;
}
.description {
  margin-top: 20px;
  font-family: $fontFamily;
  color: black;
}
.itemWrapper {
  border-radius: 5px;
  border-color: $mainBorder;
  border-style: solid;
  border-width: 1px;
  margin-top: 30px;
  padding: 15px;
  font-family: $fontFamily;
  color: black;
}
.title {
  color: black;
  font-family: $fontFamilyB;
}
.pgbar {
  :global {
    .progress-bar {
      background-color: $buttonColor;
    }
  }
}
.image {
  max-width: 100%;
}
.active {
  border-color: $buttonColor;
}
