.wrapper {
  & + & {
    margin-top: 20px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  &:first-child {
    margin-top: 15px;
  }
}