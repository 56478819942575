@import '_vars.scss';
.wrapper {
  // background: $bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px;
  text-align: center;
  margin: 40px;
  border-radius: 4px;
  border: 1px dashed $borderColorDarker;
}