@import "../../../../../vars";
.videoWrapper {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 40px;
  font-family: $fontFamily2;
}
.frameWrapper {
  margin-top: 10px;
  background-color: white;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.imgWrapper {
  width: 50px;
  height: 50px;
}
div.frameWrapper {
  color: gray;
}
