@import "../../../../../vars";
.wrapper {
  height: 100px;
  width: "100%";
  margin: 0 30px;
  border-bottom-width: 1px;
  border-bottom-color: $mainBorder;
  border-bottom-style: solid;
  .left {
    margin-right: 20px;
  }
  .right {
    padding: 13px 0px;
    .title {
      height: 33%;
      font-family: $fontFamilyB;
    }
    .detail {
      justify-content: space-between;
      align-items: center;
      height: 67%;
    }
  }
}
.container {
  width: 100%;
  align-items: center;
}
.graytext {
  color: $textLight;
  font-family: $fontFamily2;
  font-size: small;
}
.avatar {
  width: 60px;
  height: 60px;
  background-color: $mainGray;
  color: white;
  font-size: 35px;
  font-family: $fontFamily2BD;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  padding-top: 5px;
}
.detail {
  font-family: $fontFamily;
}
