@import "_vars.scss";

.header {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.emptyWrapper {
  margin: auto;
  margin-top: 100px;
  max-width: 960px;
  width: 100%;

  .emptyActionName {
    text-transform: uppercase;
  }
}

.type {
  background: $bg;
  border: $borderDefault;
  border-radius: 2px;
  padding: 3px 6px;
  font-size: 0.875rem;
  margin-top: 7px;
  display: inline-block;
  color: $textMain;

  .actionType {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
  }
}

.iconWrapper {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 50px;
}

.toggle_loader {
  min-height: 50px;
}
.marginRight {
  margin-right: 5px;
}

.searchBox {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 5px;
    line-height: 15px;
  }
  vertical-align: middle;
}

.pdfBtn {
  margin-right: 10px;
}
.yellowBtn {
  font-family: $fontFamily2;
  background: #ffb500 !important;
  border: none !important;
  border-radius: 0;
  color: black !important;
  &:hover,
  &:active {
    border-radius: 0;
    border: none;
    color: black;
  }
}

.alertMsg {
  max-width: 100%;
  font-family: $fontFamily;
}
