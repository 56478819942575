@import "../../../../vars";

.main {
  width: 80%;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 2px 10px #aaaa;
  padding: 35px;
  margin-top: 50px;
}
.description {
  margin-top: 20px;
  font-family: $fontFamily;
}
.label {
  font-family: $fontFamilyB;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}
.pgbar {
  :global {
    .progress-bar {
      background-color: $buttonColor;
    }
  }
}
.grayLabel {
  color: $textLight;
}
.addbtn {
  border-radius: 5px;
  border-style: dashed;
  border-width: 1px;
  color: $textLight;
  font-family: $fontFamily2BD;
  margin-top: 5px;
}
.sub {
  margin-top: 0px;
  margin-bottom: 10px;
  border-width: 1px;
  border-color: $mainBorder;
  border-style: solid;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  padding-top: 20px;
}
.des {
  margin-top: -10px;
  font-family: $fontFamily;
  color: black;
}
.rowwrapper {
  display: flex;
  margin-top: 4px;
}
.jpbutton,
.jpbuttonA {
  font-family: "Futura Std Bold";
  cursor: pointer;
}
.jpbuttonA {
  width: -webkit-fill-available;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: $buttonColor;
  color: black;
}
.jpbutton {
  width: -webkit-fill-available;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: $textLight;
  color: $textLight;
}
.temp {
  width: -webkit-fill-available;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}
.addtionField {
  display: flex;
  flex: auto;
}

.smalltitle {
  color: black;
  font-family: $fontFamilyB;
}
.stack {
  color: $textLight;
  font-family: $fontFamily;
  margin-right: -3px;
}
.closebtn {
  width: 12px !important;
  margin-right: 14px;
}
.inputDown {
  .inputdown_tooltiptext {
    top: 0;
  }
}
