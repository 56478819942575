@import "../../../../../vars";

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  margin: 15px 0px 15px 0px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #eaeaea;
  padding: 0;
  .textWrapper {
    margin-left: 10px;
  }
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  color: black;

  &:hover {
    background-color: $lightGray !important;
    color: #000000 !important;
  }
}
.leftWrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.iconWrapper {
  margin-right: 10px;
}
div.leftWrapper {
  font-family: $fontFamily2BD;
}
