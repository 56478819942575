@import '_vars.scss';
.title {
  font-size: 2.25rem;
  font-weight: 500;
  border-color: transparent;
  &:hover {
    border-color: #ddd;
  }
}

.wrapperProps {
  margin-left: -1rem;
  margin-right: -1rem;
  div i {
    color: $textSecondary;
    opacity: 0;
    transition: .2s all;
  }
  &:hover {
    div i {
      opacity: 1;
    }
  }
}