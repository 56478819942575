@import "_vars.scss";
.main {
  background: url($bgimg);
  background-color: #0000006b;
  background-blend-mode: color;
  background-position-y: 750px;
  // margin-left: -30px;
  // margin-right: -30px;
  padding: 0 30px;
  min-height: 180px;
  // display: flex;
  align-items: center;
  border-bottom: 9px solid #fdb301;

  .title {
    // padding-left: 50px;
    color: white;
    font-size: 3rem;

    span {
      color: #ffb500;
      font-family: $fontFamily2BD;
    }
  }
  .subtitle {
    color: white;
    font-family: $fontFamily2;
  }
}
