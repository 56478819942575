.vertical {
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
  margin-bottom: -1rem;
  position: relative;

  & > *:not(.horizontal) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &.largeGap {
    margin-top: -1rem;
    margin-bottom: -1rem;

    & > *:not(.horizontal) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  & > .horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.horizontal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: -0.5rem;
  position: relative;

  & > * {
    margin: 0.5rem 0.5rem;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 1px;
    min-width: 130px;
  }

  & > .vertical {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > & {
    margin: 0;
  }
}
