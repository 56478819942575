@import "../../../../vars";

.container {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 30px;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.middelText {
  font-family: $fontFamily2BD;
  font-size: large;
}
.leftdiv {
  flex: 1;
  .leftbutton {
    width: 34px;
    height: 34px;
    border-width: 1px;
    border-color: $mainRed;
  }
  .closebutton {
    width: 34px;
    height: 34px;
    border-width: 1px;
    border-color: $textLight;
  }
  .helpBtn {
    margin-left: 20px;
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid lightgray;
    color: gray;
    font-family: $fontFamily2;
    font-size: 13px;
    align-items: center;
    box-shadow: none;
    margin-right: 10px;
    .icon {
      font-size: 14px;
      margin-right: 5px;
    }
    .text {
      font-size: 12px;
      // color: #84329b;
    }
  }
}
.rightdiv {
  flex: 1;
  align-items: center;
  margin-right: 0px;
  justify-content: flex-end;
  display: flex;
  .rightbutton {
    background-color: $buttonColor;
    width: 34px;
    height: 34px;
    font-family: $fontFamily2;
  }
  .saveIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  .savebutton {
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid $buttonColor;
    font-family: $fontFamily2;
    font-size: 13px;
    align-items: center;
    box-shadow: none;
    margin-right: 10px;
  }
}
.savelabel {
  opacity: 0.3;
  display: flex;
  color: black;
}
