@import "../../../../vars";

.backHomeWrapper {
  display: flex;
  background-image: url(/assets/images/home_back.png);
  width: 100%;
  height: 250px;
  background-size: cover;
  padding-left: 30px;
  padding-top: 40px;
}
.backCusProfileWrapper {
  display: flex;
  background-image: url(/assets/images/cusprofile_back.png);
  width: 100%;
  height: 250px;
  background-size: cover;
  padding-left: 30px;
  padding-top: 40px;
  box-shadow: 1px 1px 10px 1px #ababab;
}

.buttonWrapper {
  height: 36px;
  border-radius: 18px;
  background-color: $buttonColor;
  width: fit-content;
  font-family: $fontFamily2;

  &:hover {
    background-color: #af6d08;
    color: #ffffff;
  }
}

h1 {
  font-family: $fontFamily2BD;
}
