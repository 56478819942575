@import '../../_vars.scss';


.PDF-viewer-content {
    height: 100%;
    overflow-y: auto;
}

.PDF-viewer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 50px 0;

    background-color: #f5f5f5;
}

.PDF-viewer__page {
    margin: 50px 5% 0 5%;
    box-shadow: 0 0 10px #afafaf;
    overflow: hidden;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 30px;
}

.header__button {
    border: none;
    padding: 0;

    background-color: transparent;
    cursor: pointer;
    font-size: 15px;
}

.header__icon {
    font-size: 20px !important; 
}

.header__close-button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: $fontFamily2;
    text-transform: uppercase;
}

.header__close-button-icon {
    color: rgb(0, 152, 216);
}

.header__envelope-button {
    border: 1px solid rgb(0, 152, 216);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    border-radius: 50%;
    color: rgb(0, 152, 216);
}

.header__title {
    margin: 0 20px;
    overflow: auto;
    
    font-family: $fontFamily2BD;
    font-size: 12px;
    text-align: center;
    word-break: break-word;
}

@media screen and (max-width: 480px) {
    .header__close-button {
        font-size: 12px;
    }
    
    .header__envelope-button  {
        flex: 1 0 auto;
        width: 32px;
        height: 32px;
    }

    .header__title {        
        font-size: 10px;
    }
}