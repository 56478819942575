@import "../../../../vars";
.container {
  overflow: scroll;
  display: block;
}
.header {
  height: 200px;
  padding: 10px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
  .headerRight {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  box-shadow: 1px 1px 2px gray;
}
.icon {
  color: $buttonColor;
  margin-right: 10px;
}
.headerLeft {
  width: 100%;
  padding: 0px;
}
.avatarWrapper {
  .avatar {
    width: 100px;
    height: 100px;
    background-color: $mainGray;
    border-radius: 12px;
    text-align: center;
    color: white;
    font-size: 64px;
    font-family: $fontFamily2BD;
  }
}
.headerMain {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerDetail {
  width: 100%;
  font-size: medium;
  font-family: $fontFamily2BD;
  margin-left: 20px;
  .detainbottom {
    font-size: small;
    font-family: $fontFamily2;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }
}
.des {
  color: $textLight;
  font-family: $fontFamily2;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.val{

  font-family: $fontFamily;
}

.tabItem_a {
  display: inline;
  border-bottom: solid $buttonColor;
  font-family: $fontFamily2BD;
  font-size: small;
  cursor: pointer;
}
.tabItem_ia {
  display: inline;
  color: gray;
  font-family: $fontFamily2BD;
  font-size: small;
  cursor: pointer;
}
.tabBar {
  height: 50px;
  margin-left: 30px;
  margin-top: 10px;
}
.grayBar {
  height: 50px;
  background-color: $mainGray;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .btnUp {
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }
  .btnEdit {
    width: 32px;
    height: 32px;
    border-color: white;
    border-width: 1px;
    background-color: transparent;
    margin-right: 15px;
  }
  .butplus {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    background-color: $buttonColor;
  }
  .btnlink {
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }
}
.bottomWrapper {
  margin: 40px 30px 30px 30px;
  font-size: small;
  font-family: $fontFamily2BD;
}
.pair {
  margin: 0 0 20px 0;

  font-family: $fontFamily;
}
.buttonpair {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.labelpercent {
  color: white;
  text-align: center;
  background-color: $mainGray;
  border-radius: 5px;
  font-size: medium;
  font-family: $fontFamily2BD;
  margin-right: 15px;
  width: 70px;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 2px;
}
.label {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: $fontFamilyB;
}
.surgeon {
  color: $textLight;
  font-family: $fontFamily;
}
.subBottom {
  display: flex;
}
.leftborder {
  width: 2px;
  height: auto;
  background-color: $mainGray;
  // margin-top: 5px;
  // margin-bottom: 5px;
  margin-right: 10px;
}
.subspec {
  display: flex;
}
.dropbtn {
  text-align: center;
  width: 100%;
  font-family: $fontFamily2;
  letter-spacing: 1px;
  height: 40px;
}

.button {
  margin: 10px 0px;
  height: 40px;
  width: 100%;
  background: #ffb500;
  font-family: $fontFamily2;
  letter-spacing: 1px;
  border-radius: 0;
}
.dropline {
  width: 100%;
  height: 1px;
  background-color: $textLight;
}
.red {
  color: $mainRed !important;
}
.black {
  color: $mainBlack !important;
}
.bottomTitle {
  color: $mainBlack;
  font-family: $fontFamily2BD;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bottomSubTitle {
  font-family: $fontFamilyB;
  color: $mainBlack;
  font-size: small;
}
.grayLine {
  background-color: $textLight;
  width: 100%;
  height: 2px;
  margin-top: 2px;
}
.subContainer {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.subContainerLeft {
  width: 25%;
}
.imagecontainer {
  text-align: center;
  border: 1px solid $textLight;
  height: auto;
}
.subContainerRight {
  width: 75%;
  // margin: auto;
}
.image {
  max-width: 100%;
  height: 122px;
  // width: 100%;
  // height: auto;
}

.partNum,
.product {
  white-space: pre-line;
}

.partNum {
  width: 25%;
  margin: 3px 0px;
}
.product {
  width: 60%;
  margin: 3px 0px;
  padding: 3px 5px 3px 0px;
}
.category {
  width: 35%;
  margin: 3px 0px;
}
.qty {
  display: flex;
  justify-content: flex-end;
  width: 27%;
  margin: 3px 0px;
}

.equipment-list__section-title {
  letter-spacing: 0.5px;
}

.subContainerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lighttext {
  font-size: 13px;
  font-family: $fontFamily2;
  color: $textLight;
}
.blackText {
  font-family: $fontFamily;
}
.addons {
  display: flex;
  font-family: $fontFamily2;
  color: $textLight;
  align-items: center;
}
.lightline {
  width: -webkit-fill-available;
  height: 1px;
  background-color: $textLight;
}
.addonText {
  width: 170px;
  text-align: center;
  margin: 0px 15px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.textfiledBottom {
  height: 30px;
  width: 40px;
  padding: 10px 5px !important;
  text-align: center;
}
.savebtn {
  padding: 0 10px;
  height: 30px;
  border-radius: 15px;
  font-family: $fontFamily2;
  font-size: 11px;
  align-items: center;
  background-color: $buttonColor;
}
.saveIcon {
  width: 10px !important;
  height: 10px !important;
  margin-right: 5px;
}
.cancelbtn {
  padding: 0 10px;
  height: 30px;
  border-radius: 15px;
  font-family: $fontFamily2;
  font-size: 11px;
  align-items: center;
  background-color: transparent;
  color: white;
}

.edit-outlined-icon {
  width: 20px !important;
}
.edit-summary-outlined-icon {
  width: 20px !important;
}

.link-out-icon {
  transform: rotate(-45deg);
}
.btnSquare {
  display: flex;
  height: 25px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.btnAddSummary {
  .add-outlined-icon {
    font-size: 13px !important;
    margin-right: 5px !important;
  }
  width: 100%;
  font-family: 'Futura Std Bold' !important;
}

.detailText {
  font-family: "HumstSlab712 BT" !important;
}

.summaryWrapper {
  padding: 15px;
  box-shadow: 0px 0px 5px 0px #b3b3b3;
  border: 1px solid #d7d7d7;
  .summaryHeader {
    justify-content: space-between;
  }
  .editSummaryBtn {
    width: 1.5rem;
    height: 1.5rem;
    .editSummaryIcon {
      font-size: 15px;
    }
  }
  .detailText {
    margin-top: 7px;
  }
  .planSummaryDetail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .column {
      flex: 1 1 50%;
      margin-top: 20px;
    }
  }
}

.editContainer {
  margin: 0 0 10px 0;
  padding: 0 10px;
  background: #f1f1f1 !important;
  // background: #f1f1f1 !important;
  align-items: center;
  .editBox {
    background: none !important;
    align-items: center;
    .editLabel {
      font-size: smaller;
    }
    :global {
      .btn {
        margin: 0 10px;
        padding: 0;
      }
    }
    .btnMinus {
      margin: 0;
    }
    .verticalLine {
      margin: 0 5px;
    }
    .removeAll {
      padding: 5px 15px;
      border-radius: 18px;
      border: 1px solid #606060;
      height: 24px;
      font-size: smaller;
    }
  }
  .infoSection {
    background: none !important;
    justify-content: flex-end;
  }
}
.infoContent {
  background: #54575a !important;
  color: white;
  padding: 5px;
  transition: all 0.5s;
  border-radius: 4px;
  & > span {
    position: relative;
    width: 100%;
    white-space: pre-line;
    font-family: $fontFamily;
    font-size: 13px;
  }
  .arrow {
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: -10px;
    right: 12px;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #54575a;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.show {
  display: flex;
}
.hide,
.collapsed {
  display: none !important;
}
.equipmentBox {
  transition: all 0.5s;
}

.summaryItem {
  .title {
  }
  .detail {
  }
}

.verticalGap {
  margin-bottom: 10px;
}

.specialty {
  .subContainerRow {
    .left {
      .image {
        height: 80px;
        border: 1px solid gray;
        border-radius: 5px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      .label {
        margin: 0;
      }
    }
  }
}

.implants {
  .bottomTitle {
    padding: 5px 10px;
  }
  .btnSquare {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #484848;
    border-radius: 3px;
  }
  .first {
    background-color: #b7cbca;
  }
  .second {
    background-color: #cfb6d3;
  }
  .third {
    background-color: #dec59d;
  }
  .grayLine {
    margin-top: 0;
  }
}
