.wrapper {}

.row {
  display: flex;
  margin: 5px 0;
}

.addRemoveWrapper {
  display: flex;
  flex-basis: 38px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
  transition: .1s all;
  opacity: .2;

  &:hover {
    transform: scale(1);
    opacity: 1;
  }
}

.selectWrapper {
  flex-grow: 1;
}