@import "../../../../vars";

.modalWrapper {
  background-color: white;
  padding: 30px;
  margin: auto;
  font-family: $fontFamily2BD;
  max-width: 450px;
  min-width: 300px;
  display: block;
}
.modalBtn {
  margin: 10px 0 0 !important;
}
.submitBtn {
  background-color: $buttonColor;
}
.deleteBtn,
.deleteBtn:hover {
  border: 1px solid red;
  color: red;
}
.description {
  font-family: $fontFamily;
  margin-top: 0px;
}
.button {
  margin: 10px 0px;
  height: 40px;
  width: 100%;

  font-family: $fontFamily2;
  letter-spacing: 1px;
  border-radius: 0;
}
.exportBtn {
  // border: 1px solid $mainRed;
  background-color: $buttonColor;
  // color: $mainRed;
}
.middle {
  border: 1px solid $buttonColor;
  color: black;
}
.downbtn {
  border: 1px solid $textLight;
  color: $textLight;
}
.yellowbtn {
  background-color: $buttonColor;
}
.sharelinkWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $textLight;
  border-radius: 6px;
  padding-left: 10px;
  color: black;
  font-family: $fontFamily;
}
.sharebtn {
  background-color: $buttonColor !important;
  min-width: 38px;
}
.switchWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global {
    .toggle {
      margin-right: -10px;
    }
    // .form-check-label:before {
    //   background: $buttonColor !important;
    //   border-color: $buttonColor !important;
    // }
  }
}
.label {
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.duplicate-button {
  background-color: $buttonColor;
  border-radius: 0;
}

.duplicate-button:hover,
.duplicate-button:focus {
  border: none;

  background-image: none;
  box-shadow: none;
}

.upbtn:hover {
  border: 1px solid $mainRed;

  background-image: none;
  box-shadow: none;
}

.summaryInput {
  :global {
    textarea {
      resize: vertical;
      height: 150px;
    }
  }
}
