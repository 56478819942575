@import "../../../vars";
.container {
  flex: 1;
}
.Wrapper {
  background-color: white;
  border: 1px solid $mainBorder;
  font-family: $fontFamily2;
  display: flex;
  padding: 0 15px;
  width: 100%;
  justify-content: space-between;
  :global {
    .form-group {
      width: 100%;
    }
  }
  border-radius: 5px;
  padding-right: 0px;
}
.activeWrapper {
  border-width: 1px;
  border-color: $buttonColor;
}
.textfield {
  height: 100%;
  border: none;
  width: 100%;
  margin-right: 30px;
  font-size: small;
  font-family: $fontFamily2BD;
  text-overflow: ellipsis;
  .text:hover {
    text-overflow: unset;
  }
}
.label {
  border: none;
  font-size: small;
  font-family: $fontFamily2BD;
  color: rgb(206, 207, 210);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
  flex: auto;
  white-space: break-spaces;
  margin: 5px 0px;
}
.textfield:focus:active:before {
  border: none;
}
.percentbtn {
  height: 30px;
  margin: 0px 5px;
  padding: 10px;
  width: 50px;
}
.plusbtn {
  height: 30px;
  width: 30px;
}
.rightgroup {
  display: flex;
  align-items: center;
  margin: 5px 0px !important;
}
.errorMessage {
  color: red;
}
.Wrapper {
  margin-bottom: 12px;
  margin-top: 12px;
}
