@import "../../../../vars";

h1 {
  font-family: $fontFamily2BD;
}
.Wrapper {
  overflow: scroll;
  display: block;
  background-color: #f1f1f1 !important;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f1f1f1;
  justify-content: center;
  padding: 35px 0;
}

.notFound {
  display: flex;
  justify-content: center;
  margin-top: 20rem;
}
// .main {
//   width: 80%;
//   background-color: white;
//   height: fit-content;
//   box-shadow: 0px 2px 10px #aaaa;
//   padding: 35px;
// }
// .description {
//   margin-top: 20px;
//   font-family: $fontFamily;
// }
// .label {
//   font-family: $fontFamilyB;
// }
// .pgbar {
//   :global {
//     .progress-bar {
//       background-color: $buttonColor;
//     }
//   }
// }
