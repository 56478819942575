@import '../../vars.scss';

.pagination-item {
  display: block;
  padding: .5rem .7rem;
  color: $textBase;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  min-width: 35px;

  &.pagination-item-disabled {
    color: $textLight;
    pointer-events: none;
  }

  &:global(.active) {
    color: $primary;
    font-weight: 600;
  }

  &:hover {
    color: $textMain;
  }
}