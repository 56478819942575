@import '../../vars.scss';
.wrapper {
  padding: 25px 30px;
  cursor: pointer;
  
  &+& {
    border-top: $borderDefault;
  }
  &.grey {
    background-color: $bg;
  }
}