.video-player-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 768px;
  }
  
  .video-player-modal__container {
    position: relative;
  }
  
  .video-player-modal__close-button {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
  }
  
  .video-player-modal__close-button-icon {
    display: block !important;
    color: #ffffff;
  }
  
  .video-player-modal__video-player {
    width: 100%;
  }