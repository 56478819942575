.header--value-propositions {
    background-image: url(/assets/images/value-propositions/header-image.png);
}

.header--customer-snapshots {
    background-image: url(/assets/images/customer-snapshots/header-image.png);
}

.header--video-library {
    background-image: url(/assets/images/video-library/header-image.png);
}

.header--virtual-ORs {
    background-image: url(/assets/images/VirtualORsAndProductFair/header-image-matterPort.png);
}

.header {
    height: 250px;
    padding: 40px 0 0 30px;
    width: 100%;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (max-width: 480px) {
    .header__title {
        font-size: 24px;
    }
}