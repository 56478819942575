@import "_vars.scss";
.main {
  display: flex;
  justify-content: space-around;
  .tabItem {
    width: 180px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px 0px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      text-align: center;
      font-size: 0.7rem;
    }
    img {
      // margin-left: 60px;
      width: 50px;
    }
  }
  .selected {
    border: 1px solid $imgTabColor;
    p {
      color: $imgTabColor;
    }
  }
}
