@import "../../../../vars";
:export {
  buttonColor: $buttonColor;
}
.container {
  display: block;
  overflow: scroll;
}
.avatarWrapper {
  height: 140px;
  align-items: center;
  padding: 0px 40px;
  display: flex;
  box-shadow: 0px 2px 3px lightgray;
  justify-content: space-between;
}
.avatar {
  :global {
    .avatar-placeholder {
      background-color: $mainGray;
      font-family: $fontFamily2BD;
    }
  }
}
.labelName {
  margin-left: 20px;
  font-family: $fontFamily2BD;
  margin-top: 0px;
  font-size: 23px;
}
.tabBar {
  height: 50px;
  margin-left: 30px;
  margin-top: 20px;
}
.tabItem_a {
  display: inline;
  border-bottom: solid $buttonColor;
  font-family: $fontFamily2BD;
  font-size: 15px;
  cursor: pointer;
}
.tabItem_ia {
  display: inline;
  color: $textLight;
  font-family: $fontFamily2BD;
  font-size: 15px;
  cursor: pointer;
}

.tabWidgetWrapper {
  margin: 0 30px;
  font-family: $fontFamilyB;
}

.notiItemWrapper {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px;
  font-family: $fontFamily2BD;
  font-size: small;
}
.shareItemWrapper {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  .key {
    color: $textLight;
    font-family: $fontFamily2;
    font-size: 12px;
  }
  .value {
    font-family: $fontFamily;
    font-size: 12px;
  }
}

.shareLinkItem {
  font-size: medium;
  font-family: $fontFamily2BD;
  font-size: 14px;
}
.headerLeft {
  display: flex;
  align-items: center;
}
.saveButton,
.btndownload {
  width: fit-content;
  font-family: $fontFamily2;
  padding-left: 30px;
  padding-right: 30px;
  color: #000000 !important;
  background-color: $buttonColor !important;
  border-radius: 0;
}
.btndownload {
  min-width: 200px;
  justify-content: start;
  .icon {
    margin-right: 10px;
  }
}
.toggle {
  :global {
    .form-check-label:before {
      background-color: $buttonColor;
      border-color: $buttonColor;
    }
  }
}
.btncopy {
  height: 34px;
  border-radius: 17px;
  padding-left: 27px;
  padding-right: 21px;
  color: $textLight;
  font-family: $fontFamily2;
  font-size: 13px;
}
