@import "../../../../vars";

.main {
  width: 80%;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 2px 10px #aaaa;
  padding: 35px;
  margin-top: 50px;
}
.description {
  margin-top: 20px;
  font-family: $fontFamily;
}
.label {
  font-family: $fontFamilyB;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  padding-right: 48px;
}
.pgbar {
  :global {
    .progress-bar {
      background-color: $buttonColor;
    }
  }
}
.addbtn {
  border-radius: 5px;
  border-style: dashed;
  border-width: 1px;
  color: $textLight;
  font-family: $fontFamily2BD;
  margin-top: 0px;
  margin-bottom: 0px;
}
.addtionField {
  display: flex;
}
.inputdiv {
  margin: 18px 0px 8px 0px;
}
.smalltitle {
  color: black;
  font-family: $fontFamilyB;
}
.stack {
  color: $textLight;
  font-family: $fontFamily;
  margin-right: -6px;
}
.closebtn {
  width: 12px !important;
  margin-right: 14px;
}
