@import "../vars.scss";

.app {
  height: 100%;
  max-width: 1400px;
  margin: auto;
  /*padding-top: 100px;*/
  background: #fbfbfd;
}

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  background: $bg;
  background: $bodybg !important;
}

body {
  background: $bodybg !important;
  max-width: 768px;
  margin: auto !important;
}
  
:global {
  .hidden {
    opacity: 0 !important;
    visibility: hidden;
  }

  .hidden-mobile {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: $fontFamily;
  background: $bg;
  color: #000000;
  font-size: 16px;
  overflow: hidden;
  font-weight: 400; // font smoothing
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  input,
  button,
  textarea {
    color: $textMain;
    font-size: 16px;
    font-family: $fontFamily;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    transition: all 0.2s;
    margin: 0;
    line-height: 1.5;
    font-weight: 500;
    color: #000000;
    // font-family: $fontFamily1;
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4,
  * + h5 {
    margin-top: 1.125rem;
  }

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + * {
    margin-top: 1em;
  }

  p {
    transition: all 0.2s;
    margin: 0;
    line-height: 1.375rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: $textSecondary;
  }

  input,
  textarea {
    font-size: 0.875rem;
  }

  * + p {
    margin-top: 0.3em;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  p,
  ul,
  ol {
    & + * {
      margin-top: 1.125em;
    }
  }

  p + p {
    margin-top: 0.4em;
  }

  a {
    transition: 0.2s;
    text-decoration: none;
    cursor: pointer;
  }

  p,
  ul {
    a {
      display: inline-block;
      position: relative;
      transition: all 0.2s;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-weight: 500;
  }

  ul,
  ol {
    line-height: 1.5;
    color: $textMain;
    font-size: 0.875rem;

    *:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ol {
    padding-left: 20px;
  }

  ul:global(.no-style) {
    list-style: none;
    padding-left: 0;

    ul {
      padding-left: 20px;
    }
  }

  code:not([class]) {
    display: inline-block;
    background: #fbfbfd;
    border: 1px solid #dadee7;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #34aa44;
    line-height: 1.375rem;
    padding: 4px 12px;
    font-family: $fontFamily;
  }

  a:hover {
    color: #709795;
  }
}

:global #root {
  height: 100%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $textSecondary;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $textSecondary;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $textSecondary;
}

form {
  max-width: 760px;
}

// some helpers and pregenerated color classes
@each $name, $color in $colorPairs {
  :global(.color-#{$name}) {
    color: $color;
  }
}

body label {
  text-transform: inherit;
}

:global {
  .form-group label {
    font-size: 0.875rem;
  }

  //Kendo Progressbar style
  .k-stepper {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: inherit;
    background: none;
  }

  .k-stepper .k-step-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
  }

  .k-stepper .k-step-link {
    outline: none;
    text-decoration: none;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
  }

  .k-stepper .k-step-disabled {
    pointer-events: none;
  }

  .k-stepper .k-step-disabled .k-step-link {
    cursor: default;
  }

  .k-stepper .k-step-indicator {
    border-radius: 50%;
    margin: 3px;
    width: 28px;
    height: 28px;
    border-width: 1px;
    border-style: solid;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: visible;
    transition-property: color, background-color, border-color;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
  }

  .k-stepper .k-step-indicator::after {
    border-radius: 100%;
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: 2;
  }

  .k-stepper .k-step-focus .k-step-indicator::after,
  .k-stepper .k-step-link:focus .k-step-indicator::after {
    display: block;
  }

  .k-stepper .k-step-label {
    max-width: 10em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
  }

  .k-stepper .k-step-label:only-child {
    border-radius: 15px;
    padding: 5px 12px;
    border-width: 0;
  }

  .k-stepper .k-step-label .k-step-text {
    max-width: calc(10em - 20px);
    -ms-flex-positive: 1;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .k-stepper .k-step-label .k-icon {
    margin-left: 4px;
  }

  .k-stepper .k-step-current .k-step-label {
    font-weight: bold;
  }

  .k-stepper .k-step-label .k-step-label-optional {
    font-style: italic;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .k-progressbar {
    border-radius: 2px;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    line-height: 2;
    display: inline-grid;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .k-progressbar > .k-state-selected {
    border-width: 0;
    border-style: solid;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  .k-progressbar > ul {
    border-width: inherit;
    border-style: inherit;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 1;
    flex: 1;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  .k-progressbar .k-item {
    display: block;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
  }

  .k-progress-status-wrap {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  .k-progress-status-wrap.k-progress-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .k-progress-status-wrap.k-progress-center {
    -ms-flex-pack: center;
    justify-content: center;
  }

  .k-progress-status-wrap.k-progress-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .k-progress-status {
    padding: 0 0.5em;
    -webkit-padding-before: 0;
    padding-block-start: 0;
    -webkit-padding-after: 0;
    padding-block-end: 0;
    -webkit-padding-start: 0.5em;
    padding-inline-start: 0.5em;
    -webkit-padding-end: 0.5em;
    padding-inline-end: 0.5em;
    min-width: 10px;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
  }

  .k-progressbar-horizontal {
    width: 27em;
    height: 5px;
    /* grid-template-columns: 1fr; */
    grid-template-rows: 100%;
  }

  .k-progressbar-horizontal .k-progress-status-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .k-progressbar-horizontal > .k-state-selected {
    width: 0;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .k-progressbar-horizontal > ul {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .k-progressbar-horizontal .k-item + .k-item {
    border-left-width: 1px;
    border-inline-start-width: 1px;
    border-inline-end-width: 0;
  }

  .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    justify-self: flex-end;
  }

  .k-progressbar-vertical {
    width: 5px;
    height: 27em;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
  }

  .k-progressbar-vertical .k-progress-status-wrap {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .k-progressbar-vertical .k-progress-status {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .k-progressbar-vertical > .k-state-selected {
    height: 0;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .k-progressbar-vertical > ul {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .k-progressbar-vertical .k-item + .k-item {
    border-top-width: 1px;
    border-block-start-width: 1px;
    border-block-end-width: 0;
  }

  .k-progressbar-vertical.k-progressbar-reverse {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .k-progressbar-indeterminate .k-progress-status-wrap,
  .k-progressbar-indeterminate .k-state-selected,
  .k-progressbar-indeterminate .k-item {
    display: none;
  }

  .k-rtl .k-progressbar,
  .k-progressbar[dir="rtl"] {
    direction: rtl;
  }

  .k-progressbar {
    display: -ms-inline-grid;
  }

  .k-progressbar > .k-progress-status-wrap,
  .k-progressbar > .k-state-selected {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .k-progressbar-horizontal {
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 5px;
  }

  .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
    -ms-grid-column-align: end;
  }

  .k-progressbar-vertical {
    -ms-grid-columns: 5px;
    -ms-grid-rows: 1fr;
  }

  .k-progressbar-vertical > .k-state-selected {
    -ms-grid-row-align: end;
  }

  .k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
    -ms-grid-row-align: start;
  }

  .k-progressbar {
    border-radius: 0;
    overflow: visible;
  }

  .k-progressbar .k-progress-status-wrap {
    font-size: 11px;
  }

  .k-progressbar-horizontal .k-progress-status-wrap {
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .k-progressbar-horizontal .k-progress-status {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  .k-progressbar-vertical .k-progress-status-wrap {
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .k-progressbar-vertical .k-progress-status {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  .k-progressbar-indeterminate {
    overflow: hidden;
  }

  .k-progressbar-indeterminate::before,
  .k-progressbar-indeterminate::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #3f51b5;
    position: absolute;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .k-progressbar-indeterminate.k-progressbar-horizontal::before {
    top: 0;
    left: -150%;
    -webkit-animation: progressbar-primary-indeterminate-horizontal 2s infinite;
    animation: progressbar-primary-indeterminate-horizontal 2s infinite;
  }

  .k-progressbar-indeterminate.k-progressbar-horizontal::after {
    top: 0;
    left: -50%;
    -webkit-animation: progressbar-secondary-indeterminate-horizontal 2s
      infinite;
    animation: progressbar-secondary-indeterminate-horizontal 2s infinite;
  }

  .k-progressbar-indeterminate.k-progressbar-vertical::before {
    top: -150%;
    left: 0;
    -webkit-animation: progressbar-primary-indeterminate-vertical 2s infinite;
    animation: progressbar-primary-indeterminate-vertical 2s infinite;
  }

  .k-progressbar-indeterminate.k-progressbar-vertical::after {
    top: -50%;
    left: 0;
    -webkit-animation: progressbar-secondary-indeterminate-vertical 2s infinite;
    animation: progressbar-secondary-indeterminate-vertical 2s infinite;
  }

  @-webkit-keyframes progressbar-primary-indeterminate-horizontal {
    0% {
      -webkit-transform: translateX(0) scaleX(0);
      transform: translateX(0) scaleX(0);
    }
    20% {
      -webkit-transform: translateX(0) scaleX(0.08);
      transform: translateX(0) scaleX(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
      animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
    }
    60% {
      -webkit-transform: translateX(83%) scaleX(0.75);
      transform: translateX(83%) scaleX(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
      animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
    }
    100% {
      -webkit-transform: translateX(250%) scaleX(0.08);
      transform: translateX(250%) scaleX(0.08);
    }
  }

  @keyframes progressbar-primary-indeterminate-horizontal {
    0% {
      -webkit-transform: translateX(0) scaleX(0);
      transform: translateX(0) scaleX(0);
    }
    20% {
      -webkit-transform: translateX(0) scaleX(0.08);
      transform: translateX(0) scaleX(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
      animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
    }
    60% {
      -webkit-transform: translateX(83%) scaleX(0.75);
      transform: translateX(83%) scaleX(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
      animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
    }
    100% {
      -webkit-transform: translateX(250%) scaleX(0.08);
      transform: translateX(250%) scaleX(0.08);
    }
  }

  @-webkit-keyframes progressbar-secondary-indeterminate-horizontal {
    0% {
      -webkit-transform: translateX(0) scaleX(0.08);
      transform: translateX(0) scaleX(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
      animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
    }
    22% {
      -webkit-transform: translateX(34%) scaleX(0.45);
      transform: translateX(34%) scaleX(0.45);
      -webkit-animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
      animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
    }
    46% {
      -webkit-transform: translateX(82%) scaleX(0.75);
      transform: translateX(82%) scaleX(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
      animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
    }
    100% {
      -webkit-transform: translateX(160%) scaleX(0.08);
      transform: translateX(160%) scaleX(0.08);
    }
  }

  @keyframes progressbar-secondary-indeterminate-horizontal {
    0% {
      -webkit-transform: translateX(0) scaleX(0.08);
      transform: translateX(0) scaleX(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
      animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
    }
    22% {
      -webkit-transform: translateX(34%) scaleX(0.45);
      transform: translateX(34%) scaleX(0.45);
      -webkit-animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
      animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
    }
    46% {
      -webkit-transform: translateX(82%) scaleX(0.75);
      transform: translateX(82%) scaleX(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
      animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
    }
    100% {
      -webkit-transform: translateX(160%) scaleX(0.08);
      transform: translateX(160%) scaleX(0.08);
    }
  }

  @-webkit-keyframes progressbar-primary-indeterminate-vertical {
    0% {
      -webkit-transform: translateY(0) scaleY(0);
      transform: translateY(0) scaleY(0);
    }
    20% {
      -webkit-transform: translateY(0) scaleY(0.08);
      transform: translateY(0) scaleY(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
      animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
    }
    60% {
      -webkit-transform: translateY(83%) scaleY(0.75);
      transform: translateY(83%) scaleY(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
      animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
    }
    100% {
      -webkit-transform: translateY(250%) scaleY(0.08);
      transform: translateY(250%) scaleY(0.08);
    }
  }

  @keyframes progressbar-primary-indeterminate-vertical {
    0% {
      -webkit-transform: translateY(0) scaleY(0);
      transform: translateY(0) scaleY(0);
    }
    20% {
      -webkit-transform: translateY(0) scaleY(0.08);
      transform: translateY(0) scaleY(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
      animation-timing-function: cubic-bezier(0.4, 0.08, 0.75, 0.8);
    }
    60% {
      -webkit-transform: translateY(83%) scaleY(0.75);
      transform: translateY(83%) scaleY(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
      animation-timing-function: cubic-bezier(0.21, 0.25, 0.58, 0.98);
    }
    100% {
      -webkit-transform: translateY(250%) scaleY(0.08);
      transform: translateY(250%) scaleY(0.08);
    }
  }

  @-webkit-keyframes progressbar-secondary-indeterminate-vertical {
    0% {
      -webkit-transform: translateY(0) scaleY(0.08);
      transform: translateY(0) scaleY(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
      animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
    }
    22% {
      -webkit-transform: translateY(34%) scaleY(0.45);
      transform: translateY(34%) scaleY(0.45);
      -webkit-animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
      animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
    }
    46% {
      -webkit-transform: translateY(82%) scaleY(0.75);
      transform: translateY(82%) scaleY(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
      animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
    }
    100% {
      -webkit-transform: translateY(160%) scaleY(0.08);
      transform: translateY(160%) scaleY(0.08);
    }
  }

  @keyframes progressbar-secondary-indeterminate-vertical {
    0% {
      -webkit-transform: translateY(0) scaleY(0.08);
      transform: translateY(0) scaleY(0.08);
      -webkit-animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
      animation-timing-function: cubic-bezier(0.18, 0, 0.54, 0.42);
    }
    22% {
      -webkit-transform: translateY(34%) scaleY(0.45);
      transform: translateY(34%) scaleY(0.45);
      -webkit-animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
      animation-timing-function: cubic-bezier(0.22, 0.25, 0.7, 0.9);
    }
    46% {
      -webkit-transform: translateY(82%) scaleY(0.75);
      transform: translateY(82%) scaleY(0.75);
      -webkit-animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
      animation-timing-function: cubic-bezier(0.33, 0.3, 0.4, 1.2);
    }
    100% {
      -webkit-transform: translateY(160%) scaleY(0.08);
      transform: translateY(160%) scaleY(0.08);
    }
  }

  .k-progressbar {
    color: rgba(0, 0, 0, 0.87);
    background-color: #c5cae9;
    margin: 0 !important;
  }

  .k-progressbar .k-state-selected {
    color: white;
    background-color: #3f51b5;
  }

  .k-progressbar .k-item {
    border-color: white;
  }

  .k-stepper .k-progressbar {
    position: absolute;
    pointer-events: none;
    z-index: 0;
  }

  .k-step-list-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .k-step-list-horizontal .k-step {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    text-align: center;
  }

  .k-step-list-horizontal .k-step-link {
    margin: auto;
    max-width: 10em;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .k-step-list-horizontal .k-step-label {
    text-align: center;
  }

  .k-step-list-horizontal .k-step-indicator + .k-step-label {
    margin-top: 5px;
  }

  .k-step-list-horizontal ~ .k-progressbar {
    width: 100%;
    height: 2px;
    top: 16px;
  }

  .k-step-list-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .k-step-list-vertical .k-step {
    min-height: 54px;
  }

  .k-step-list-vertical .k-step-label {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .k-step-list-vertical .k-step-indicator + .k-step-label {
    margin-left: 12px;
  }

  .k-step-list-vertical ~ .k-progressbar {
    width: 2px;
    height: 100%;
    min-height: 20px;
    left: 17px;
  }

  .k-step-list-vertical .k-step-content {
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .k-step-list-vertical .k-step-current .k-step-content {
    height: auto;
    overflow: visible;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 30px;
    padding: 20px 10px;
  }

  .k-rtl .k-stepper,
  .k-stepper[dir="rtl"] {
    direction: rtl;
  }

  .k-rtl .k-stepper .k-step-label .k-icon,
  .k-stepper[dir="rtl"] .k-step-label .k-icon {
    margin-left: 0;
    margin-right: 4px;
  }

  .k-rtl .k-stepper .k-step-list-vertical .k-step-indicator + .k-step-label,
  .k-stepper[dir="rtl"]
    .k-step-list-vertical
    .k-step-indicator
    + .k-step-label {
    margin-left: 0;
    margin-right: 12px;
  }

  .k-rtl .k-stepper .k-step-list-vertical ~ .k-progressbar,
  .k-stepper[dir="rtl"] .k-step-list-vertical ~ .k-progressbar {
    left: auto;
    right: 17px;
  }

  .k-step-current .k-step-label {
    font-weight: 400;
  }

  .k-stepper .k-step:hover .k-step-label,
  .k-stepper .k-step.k-step-hover .k-step-label {
    color: rgba(0, 0, 0, 0.87);
  }

  .k-stepper .k-step:hover .k-step-indicator,
  .k-stepper .k-step.k-step-hover .k-step-indicator {
    background-color: #ebebeb;
  }

  .k-stepper .k-step.k-step-disabled .k-step-indicator,
  .k-stepper .k-step:disabled .k-step-indicator {
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(118, 118, 118, 0.922);
    background-color: white;
  }

  .k-stepper .k-step-indicator {
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
  }

  .k-stepper .k-step-indicator::after {
    box-shadow: inset 0 0 0 2px white;
  }

  .k-stepper .k-step-label:only-child {
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
  }

  .k-stepper .k-step-done .k-step-indicator {
    border-color: #3f51b5;
    color: #ffffff;
    background-color: #3f51b5;
  }

  .k-stepper .k-step-done:hover .k-step-indicator,
  .k-stepper .k-step-done.k-step-hover .k-step-indicator {
    background-color: #3a4ba7;
  }

  .k-stepper .k-step-done.k-step-disabled .k-step-indicator,
  .k-stepper .k-step-done:disabled .k-step-indicator {
    border-color: #8c97d3;
    color: #ffffff;
    background-color: #8c97d3;
  }

  .k-stepper .k-step-current .k-step-indicator {
    border-color: #3f51b5;
    color: #ffffff;
    background-color: #3f51b5;
  }

  .k-stepper .k-step-current:hover .k-step-indicator,
  .k-stepper .k-step-current.k-step-hover .k-step-indicator {
    background-color: #3a4ba7;
  }

  .k-stepper .k-step-current.k-step-disabled .k-step-indicator,
  .k-stepper .k-step-current:disabled .k-step-indicator {
    border-color: #8c97d3;
    color: #ffffff;
    background-color: #8c97d3;
  }

  .k-stepper .k-step-label {
    color: rgba(0, 0, 0, 0.87);
  }

  .k-stepper .k-step-success .k-step-label .k-icon {
    color: #37b400;
  }

  .k-stepper .k-step-error .k-step-label {
    color: #f31700;
  }

  .k-stepper .k-step-error .k-step-label {
    color: #f31700;
  }

  .k-stepper .k-step-error:hover .k-step-label,
  .k-stepper .k-step-error.k-step-hover .k-step-label {
    color: #f31700;
  }

  .k-stepper .k-step-disabled .k-step-label {
    color: rgba(118, 118, 118, 0.922);
  }

  .k-stepper .k-step-focus .k-step-label:only-child,
  .k-stepper .k-step-link:focus .k-step-label:only-child {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
  }

  .k-stepper .k-step-optional .k-step-label-optional {
    opacity: 0.35;
  }

  //Ant table style
  .ant-table-wrapper {
    width: 100vw;
  }
  .ant-table-thead {
    font-family: $fontFamily2;
  }
  th.ant-table-cell {
    text-align: center;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid black !important;
    border-top: 1px solid black !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0 !important;
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-family: $fontFamily;
      color: black;
    }
    .ant-table-cell:first-child {
      font-family: $fontFamilyB;
    }
  }

  //main page static style
  .searchBox button {
    border-radius: 50px;
  }

  .dzu-dropzone {
    justify-content: center;
    overflow: auto !important;
  }
  .bg-indigo {
    background-color: $imgTabColor;
  }
  .bg-blue {
    background-color: $mainGray;
  }

  .react-pdf__Page__canvas, .react-pdf__Page__textContent {
    width: 100% !important;
    height: 100% !important;
  }

  .page-overflow {
    width: 100%;
  
    overflow: scroll;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 30px 30px 0 30px;
  }

  .cards-container-home-page {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
  }

  .cards-container-home-page .card-item {
    margin-bottom: 25px;
  }
  
  .card-item {
    margin: 0 0 30px 0;
    padding: 0 10px 0 10px;
    width: 33%;
    
    cursor: pointer;
  }
  
  .card-item__image {
    width: 100%;
    box-shadow: 2px 2px 10px #00000080;
  }
  
  .card-item__title {
    margin: 10px 0 0 0;
  
    color: #000000;
    font-family: $fontFamilyB;
    font-size: 12px;
    line-height: 1.3;
  }

  .cards-container-home-page .card-item__title {
    font-size: 16px;
  }
  
  .card-item__subtext {
    margin: 0;
  
    color: #000000;
    font-family: $fontFamily;
    line-height: 1;
  }

  .cards-container-home-page .card-item__subtext {
    font-size: 13px;
  }

  .back-button {
    margin: 15px 0;
  }

  .form-check.toggle .form-check-input:checked + .form-check-label:before {
    background-color: $buttonColor !important;
    border-color: $buttonColor !important;
  }

  .form-check.toggle .form-check-label:before {
    background-color: $darkGray40Tint !important;
    border-color: $darkGray40Tint !important;
  }

  .back-button__text {
    color: $mainBlack !important;
    font-family: $fontFamily2;
    letter-spacing: 0.4px;
  }

  .dropdown-button {
    border: 1px solid #989B9A;
  }
  
  .dropdown-button-icon {
    color: #989B9A;
    font-size: 15px !important;
  }

  @media screen and (max-width: 480px) {
    .card-item {
      width: 100%;
    }
  }
}