@import '../../vars';

.selectButton {
  min-width: 200px;
  width: 100%;
  justify-content: space-between;
  position: relative;

  & > div {
    justify-content: space-between !important;
  }

  .buttonIcon {
    padding-left: 10px;
    margin-right: -4px;
    position: relative;
    z-index: 50;
    pointer-events: none;
    color: $textSecondary;

    svg {
      display: block;
    }
  }
}

.searchInput {
  background: white;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid $borderColorDarker;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  padding: 0 40px 0 1rem;
  line-height: 34px;
  outline: none !important;
  border-radius: 4px;
  opacity: 0;
  transition: 0.1s opacity 0.1s;
  cursor: pointer;

  &:focus {
    opacity: 1;
    transition: 0.1s all;
    background-image: linear-gradient(0deg, #f6f7f9 0%, #ffffff 100%);
    border: 1px solid #bdc2d1;
    box-shadow: 0 0 1px 0 rgba(22, 29, 37, 0.1),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  }
}
