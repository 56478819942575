@import "_vars.scss";
:global {
  .nav-menu {
    position: absolute;
    width: 100%;
    background-color: inherit;
    transition: 0.5s all;
    transform: translateY(-600px);
    opacity: 0;
    z-index: 999;
  }
  .menuOpened .nav-menu {
    transform: translateY(0px);
    opacity: 1;
  }
}

.nav {
  border-right: 3px solid #2ec5f4;

  :global {
    .nav-link.active {
      background: $primary;
      * {
        color: white;
      }
    }
  }
}
.meneItemWrapper {
  height: 50px;
  align-items: center;

  color: #000000;
}
