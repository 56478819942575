@import "../../../vars";

.textFieldWrapper {
  border: 1px solid $mainBorder;
  height: 40px;
  font-size: small;
  padding-left: 15px;
  padding-right: 10px;
  .selectA {
    font-family: $fontFamily;
    margin-top: -7px;
    width: 100%;
    :global {
      .dropdown-toggle {
        background-color: white;
        border: none;
        height: 30px;
        width: 100%;
        padding-left: 0px;
      }
      .dropdown-menu {
        margin-left: -15px;
      }
      .btn {
        box-shadow: none;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .select {
    font-family: $fontFamily;
    margin-top: -7px;
    width: 100%;
    :global {
      .dropdown-toggle {
        background-color: white;
        border: none;
        height: 30px;
        width: 100%;
        padding-left: 0px;
        color: $textLight;
      }
      .dropdown-menu {
        margin-left: -15px;
      }
      .btn {
        box-shadow: none;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .text {
    margin-top: -13px;
    margin-left: -5px;
    background-color: white;
    width: fit-content;
    padding: 2px 10px;
    border: none;
    font-family: $fontFamily2;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .tooltip {
    .longtooltip {
      margin-top: -20px;
    }
  }

  .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 2px;
    padding: 2px 5px;
    font-size: 12px;

    /* Position the tooltip */
    position: absolute;

    z-index: 1;
    left: 20px;
    white-space: break-spaces;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
.icon {
  margin-top: -11px;
  margin-right: 10px;
}
.horzon {
  display: flex;
}
.Wrapper {
  margin-bottom: 12px;
  margin-top: 12px;
}
.errorMessage {
  color: red;
}
