@import "../../../../vars";

.main {
  width: 80%;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 2px 10px #aaaa;
  padding: 35px;
  margin-top: 50px;
}
.description {
  margin-top: 20px;
  font-family: $fontFamily;
}
.label {
  font-family: $fontFamilyB;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pgbar {
  :global {
    .progress-bar {
      background-color: $buttonColor;
    }
  }
}
.textFieldWrapper {
  border: 1px solid $mainBorder;
  min-height: 100px;
  font-size: small;
  padding-left: 15px;
  .textFiled {
    font-family: $fontFamily;
    border: none;
    width: 100%;
    min-height: 86px;
    margin-top: 0px;
    margin-bottom: -5px;
    &:focus {
      outline: none;
    }
  }
  .text {
    margin-top: -13px;
    margin-left: -5px;
    background-color: white;
    width: fit-content;
    padding: 2px 10px;
    font-family: $fontFamily2;
  }
}
