@import "../../../vars";

.textFieldWrapper {
  border: 1px solid $mainBorder;
  height: 40px;
  font-size: small;
  padding-left: 15px;
  .textFiled {
    font-family: $fontFamily;
    margin-top: -14px;
  }
  .text {
    margin-top: -13px;
    margin-left: -5px;
    background-color: white;
    width: fit-content;
    padding: 2px 10px;
    font-family: $fontFamily2;
  }
}
.textAreaWrapper {
  border: 1px solid $mainBorder;
  font-size: small;
  padding-left: 15px;
  .textFiled {
    font-family: $fontFamily;
    margin-top: -14px;
  }
  .text {
    margin-top: -13px;
    margin-left: -5px;
    background-color: white;
    width: fit-content;
    padding: 2px 10px;
    font-family: $fontFamily2;
  }
}
.icon {
  margin-top: -11px;
  margin-right: 10px;
  display: flex;
}
.errorMessage {
  color: $red;
  line-height: 1.375rem;
  font-family: $fontFamily;
}
