@import '_vars.scss';

.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 18px;
  color: white;
  border-radius: 2px;
  font-size: .7rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  background: $bg;

  @each $name,
  $color in $colorPairs {
    &:global(.#{$name}) {
      background-color: $color;
    }
  }
}