@import "../../../../../vars";

.main {
  width: 80%;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 2px 10px #aaaa;
  padding: 35px;
  margin-top: 50px;
}
.description {
  margin-top: 20px;
  margin-bottom: 2rem;
  font-family: $fontFamily;
}
.label {
  font-family: $fontFamilyB;
}
.pgbar {
  :global {
    .progress-bar {
      background-color: $buttonColor;
    }
  }
}
