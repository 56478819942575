@import '../../../_vars.scss';

.dashboardMenuAnimate {
  transition: .4s transform;
}

.container {
  overflow: scroll;
}

.value-propositions-container {
  padding: 30px;
}

.value-proposition {
  border-bottom: #b1b4b3 1px solid;
  align-items: center;
  display: flex;
  padding: 15px 0;
}

.value-proposition:last-child {
  border: none;
}

.value-proposition__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;

  border-radius: 5px;
  flex: 0 0 50px;
}

.value-proposition__image {
  width: 60%;
}

.value-proposition__text-container {
  margin: 0 0 0 15px;
}

.value-proposition__title {
  color: #000000;
  font-family: $fontFamily2BD;
  font-size: 16px;
  line-height: 1;
}

.value-proposition__description {
  margin: 0;

  color: #000000;
  font-family: $fontFamily;
  line-height: 1.3;
}

@media screen and (max-width: 480px) {
  .value-propositions-container {
    padding: 15px;
  }
}