@import '_vars.scss';
.defaultMenu {
  min-width: 100%;
  background: white;
  box-shadow: rgba(73, 85, 114, 0.1) 0px 0px 10px 0px;
  border-radius: 4px;
  border: 1px solid $borderColorDarker;
  display: none;
  z-index: 100;
  max-height: 300px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  &.menuOpen {
    display: flex;
    flex-direction: column;
  }
  & > button,
  & > a,
  & > span {
    flex-shrink: 0;
    border-radius: 0;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    display: flex;
    justify-content: space-between;
    text-align: left;
    text-decoration: none;
    &:hover {
      border: none;
      background: rgba(5, 0, 30, 0.02);
    }
    width: 100%;
    & > div {
      justify-content: space-between !important;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $borderColor;
    }
  }
}
