@import "_vars.scss";

.itemName {
  display: flex;
  align-items: center;
}

.image {
  @include backgroundCover();
  height: 40px;
  width: 40px;
  margin-right: 20px;
}
