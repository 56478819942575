@import "../../../vars";
.Container {
  background-color: white;
  display: block;
  overflow-y: auto;
  margin-bottom: 30px;
}
.graybar {
  background-color: $mainGray;
  height: 50px;
  align-items: center;
  display: flex;
  color: white;
  padding-left: 30px;
  font-family: $fontFamily2;
}
.link {
  color: black;
}
.link:active {
  color: black;
}
.tabItem_a {
  display: inline;
  border-bottom: solid $buttonColor;
  font-family: $fontFamily2BD;
  font-size: small;
  cursor: pointer;
}
.tabItem_ia {
  display: inline;
  color: gray;
  font-family: $fontFamily2BD;
  font-size: small;
  cursor: pointer;
}
.tabBar {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
