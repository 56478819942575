@import '../../vars.scss';
.wrapper {
  display: inline-block;
  line-height: 17px;
  user-select: none;
  .checkbox {
    width: 0px;
    height: 0px;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
  }
  .description {
    margin-top: 2px;
    margin-left: 26px;
    color: $textSecondary;
  }
  .label {
    color: $textBase;
    font-size: 0.875rem;
    line-height: 19px;
    color: $textBase;
    display: inline-flex;
    position: relative;
    cursor: pointer;
    &:hover {
      color: $textMain;
    }
  }
  .checkbox[disabled]+.label {
    cursor: not-allowed;
  }
  .checkbox:checked+.label {
    color: $textMain;
    &:hover {
      color: $textBase;
    }
  }
}

.wrapper:not(.toggle):not(.radio) {
  .label {
    &:before {
      text-align: center;
      line-height: 14px;
      padding-left: 1px;
      color: #fff;
      width: 16px;
      height: 16px;
      content: "";
      font-size: 7px;
      background-color: #FFF;
      border: 1px solid #DFE3E9;
      border-radius: 3px;
      margin-right: 10px;
      display: inline-block;
      transition: border .2s, background .2s;
      vertical-align: middle;
      transform: translateY(1px)
    }
    .checkboxIcon {
      position: absolute;
      left: 4px;
      top: 6px;
      display: none;
    }
  }
  input[disabled]+.label:before {
    /* Checkbox: */
    background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    border: 1px solid $borderColorDarker;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
    border-radius: 3px;
    /* Rectangle: */
    background: rgba(#BCBCBC, .25);
  }
  .checkbox:checked+.label {
    &:before {
      background-color: $green;
      border-color: $green;
    }
    @each $name,
    $color in $colorPairs {
      &:global(.#{$name}) {
        &:before {
          color: white;
          background-color: $color;
          border-color: $color;
        }
      }
    } // extra
    .checkboxIcon {
      display: block;
    }
  }
}

.wrapper.toggle {
  .description {
    margin-left: 50px;
  }
  .label {
    &:before,
    &:after {
      content: '';
      display: block;
      border-radius: 100px;
      transition: .2s;
    }
    &:before {
      width: 38px;
      height: 20px;
      background: $borderColor;
      border: 1px solid $borderColor;
      margin-right: 12px;
    }
    &:after {
      height: 16px;
      width: 16px;
      background: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
  .checkbox:checked+.label {
    &:before {
      background: #34AA44;
      border: 1px solid #2F9E3E;
    }
    &:after {
      transform: translateX(18px);
    }
  }
  .checkbox[disabled]+.label {
    &:after {
      opacity: 0.6;
    }
  }
}

.wrapper.radio {
  .label {
    &:before,
    &:after {
      content: '';
      display: block;
      border-radius: 100px;
      transition: .2s;
    }
    &:before {
      margin-right: 10px;
      width: 16px;
      height: 16px; // margin-right: 12px;
      /* Radio: */
      background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
      border: 1px solid #D8DCE6;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
    }
    &:after {
      height: 6px;
      width: 6px;
      background: #FFFFFF;
      position: absolute;
      top: 5px;
      left: 5px;
      opacity: 0;
    }
  }
  .checkbox:checked+.label {
    &:before {
      background-image: linear-gradient(0deg, #34AA44 0%, #38B249 100%);
      border: 1px solid #2D9C3C;
      box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.10), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    }
    &:after {
      opacity: 1;
    }
  }
  .checkbox[disabled]+.label {
    &:before {
      background: rgba(#BCBCBC, .25);
    }
  }
}